import React from 'react';
import { ReactComponent as Sberbank } from './sberbank.svg';
import { ReactComponent as Copy } from './copy.svg';
import copy from 'clipboard-copy';
import { useDispatch, useSelector } from 'react-redux';
import { setPostSuccessTransaction } from '../../../../../store/card/thunk/setPostSuccessTransaction';
import { useRouter } from '../../../../../@casino/hook/useRouter';
import { setPostCancelTransaction } from '../../../../../store/card/thunk/setPostCancelTransaction';
import { setGetTransaction } from '../../../../../store/card/thunk/setGetTransaction';
import { CircularProgress } from '@material-ui/core';
import RSHB from './rshb.png';
import { translate } from '../../../../../dictionaries';
import { ReactComponent as Alfabank } from './alfabank.svg';

const Card = ({
    formik,
    setSelected,
    payment
}) => {
    const router = useRouter();
    const [file, setFile] = React.useState();
    const [isSend, setIsSend] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const dispatch = useDispatch();
  
    const sum = formik?.values.amount;
    const cards = useSelector((state) => state.card.card);
    
    const card = cards.card;
    const bank = cards.bank;

    const handleCopyCard = () => {
        copy(card);
    };

    const handleCopySum = () => {
        copy(sum);
    };

    const loadBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

    const onSubmit = () => {
        setIsLoading(true)
        const callbackSuccess = () => {
            setIsSend(true)
            setIsLoading(false);
            dispatch(setGetTransaction()); 
        }
        const formData = new FormData();
        formData.append('sum', sum);
        formData.append('card', card);
        formData.append('file', file.file)
        formData.append('transaction', formik.values.transaction);
        dispatch(setPostSuccessTransaction(formData, callbackSuccess))
    }
    const now = new Date().getTime();
    const diff = +(Number(formik.values.created) + 900000) - now;

    const getDate = (val) => {
        const now = new Date().getTime();
        const diff = +val - now;

        let hours = (diff / 1000) / 60 / 60;
        let minutes = (diff - Math.floor(hours) * 1000 * 60 * 60) / 1000 / 60;
        let seconds = (diff - (Math.floor(hours) * 1000 * 60 * 60 + Math.floor(minutes) * 1000 * 60)) / 1000;

        return `${Math.floor(minutes) < 10 ? '0' + Math.floor(minutes) : Math.floor(minutes || 0)} ${translate('common.489')} ${Math.round(seconds) < 10 ? '0' + Math.round(seconds) : Math.round(seconds || 0)} ${translate('common.491')}`;
    }

    const [left, setLeft] = React.useState(1);

    let timer = null;

    React.useEffect(() => {
        timer = setInterval(() => {
            if (left < 10) {
                setLeft(left + 1);
            } else {
                setLeft(1)
            }
        }, 1000);

        if (diff < 0) {
            formik.setFieldValue('amount', 0);
            formik.setFieldValue('isCard', false);
            formik.setFieldValue('method', 'visa-qiwi');
            formik.setFieldValue('min', 0);
            formik.setFieldValue('transaction', '');
            formik.setFieldValue('cardType', '');
            formik.setFieldValue('balanceSelected', '');
            dispatch(setGetTransaction());
            setSelected(false)
        }
        return () => {
            clearInterval(timer);
        }
    }, [left]);

    const profile = useSelector(state => state.profile.view);

    const handleCancelTransaction = () => {
        formik.setFieldValue('amount', 0);
        formik.setFieldValue('isCard', false);
        formik.setFieldValue('method', 'visa-qiwi');

        formik.setFieldValue('transaction', '');

        formik.setFieldValue('balanceSelected', 'slot');
    
        const payments = payment.filter((item) => item.status && item.currency === profile.currency);

        formik.setFieldValue('cardType', 777);
        formik.setFieldValue('min', profile?.balance < payments?.[0]?.limit_min ? payments?.[0]?.limit_min : profile?.balance);
        formik.setFieldValue('max', payments?.[0]?.limit_max);
        
        setSelected(false);

        dispatch(setPostCancelTransaction({
            transaction: formik.values.transaction
        }))
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 20 }}>
            <div>
                <h3 style={{ padding: 0, margin: 0, color: '#FFF', fontFamily: 'Montserrat, sans-serif', fontSize: 18 }}>{translate('common.471')}</h3>
            </div>
            {
                isSend && (
                    <div style={{ padding: 20, background: 'rgba(0,0,0,.2)', borderRadius: 5, display: 'flex', flexDirection: 'column', rowGap: 20 }}>
                        <div style={{ textAlign: 'center' }}>
                            <span style={{ color: '#EEE', fontFamily: 'Montserrat, sans-serif', fontSize: 14, fontWeight: 400 }}>{translate('common.472')}</span>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <div style={{ padding: '5px 10px', background: 'linear-gradient(90deg, #6F59A3 0%, #9280BA 50%, #9280BA 99.99%)', borderRadius: 3, cursor: 'pointer' }} onClick={() => router.history.push('/')}>
                                <span style={{ color: '#FFF', fontFamily: 'Montserrat, sans-serif', fontSize: 14, fontWeight: 300 }}>{translate('common.473')}</span>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                !isSend && (
                    <div style={{ padding: 20, background: 'rgba(0,0,0,.2)', borderRadius: 5, display: 'flex', flexDirection: 'column', rowGap: 20 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ display: 'flex', columnGap: 10 }}>
                                <span style={{ color: '#ff450d', fontFamily: 'Montserrat, sans-serif', fontSize: 14, fontWeight: 400, cursor: 'pointer' }} onClick={handleCancelTransaction}>{translate('common.474')}</span>
                            </div>
                            {
                                bank === 'sberbank' && (
                                    <Sberbank style={{ height: 30, width: 'auto' }} />
                                )
                            }
                            {
                                bank === 'rshb' && (
                                    <img src={RSHB}  style={{height: 50}}/>
                                )
                            }
                            {
                                bank === 'alfabank' && (
                                    <Alfabank style={{ height: 30, width: 'auto' }} />
                                )
                            }
                            
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 5 }}>
                                <span style={{ color: '#EEE', fontFamily: 'Montserrat, sans-serif', fontSize: 14, fontWeight: 400 }}>{translate('common.475')}</span>
                                <span style={{ color: '#FFF', fontFamily: 'Montserrat, sans-serif', fontSize: 18, fontWeight: 700 }}>{card?.slice(0, 4) + ' '+ card?.slice(4, 8) + ' ' + card?.slice(8, 12) + ' ' + card?.slice(12)}</span>
                            </div>
                            <div>
                                <Copy style={{ width: 30, height: 30, cursor: 'pointer' }} onClick={handleCopyCard} />
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 5 }}>
                                <span style={{ color: '#EEE', fontFamily: 'Montserrat, sans-serif', fontSize: 14, fontWeight: 400 }}>{translate('common.476')}</span>
                                <span style={{ color: '#FFF', fontFamily: 'Montserrat, sans-serif', fontSize: 18, fontWeight: 700 }}>{new Intl.NumberFormat('ru-RU').format(sum)} ₽</span>
                            </div>
                            <div>
                                <Copy style={{ width: 30, height: 30, cursor: 'pointer' }} onClick={handleCopySum} />
                            </div>
                        </div>
                        <div>
                            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 5 }}>
                                <span style={{ color: '#EEE', fontFamily: 'Montserrat, sans-serif', fontSize: 14, fontWeight: 400 }}>{translate('common.477')}</span>
                                <span style={{ color: '#FFF', fontFamily: 'Montserrat, sans-serif', fontSize: 18, fontWeight: 700 }}>{translate('common.478')}</span>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 30 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 5 }}>
                                <span style={{ color: '#EEE', fontFamily: 'Montserrat, sans-serif', fontSize: 14, fontWeight: 400 }}>{translate('common.479')}</span>
                                {
                                    !file && (
                                        <label htmlFor="check" style={{ display: 'flex' }}>
                                            <div style={{ padding: '5px 10px', background: 'linear-gradient(90deg, #6F59A3 0%, #9280BA 50%, #9280BA 99.99%)', borderRadius: 3, cursor: 'pointer' }}>
                                                <span style={{ color: '#FFF', fontFamily: 'Montserrat, sans-serif', fontSize: 14, fontWeight: 300 }}>{translate('common.480')}</span>
                                            </div>
                                            <input type="file" name="check" id="check" style={{ width: 0, height: 0, opacity: 0 }} accept="image/*,.pdf" onChange={async (e) => {
                                                const file = e.target.files[0];
                                                const base64 = await loadBase64(file);
                                                setFile({
                                                    base64,
                                                    file
                                                })
                                            }} />
                                        </label>
                                    )
                                }
                                {
                                    file && (
                                        <div>
                                            <img src={file?.base64} alt="" style={{ width: 50, height: 50, objectFit: 'cover' }} />
                                        </div>
                                    )
                                }
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', rowGap: 5 }}>
                                {
                                    !file && (
                                        <span style={{ color: '#FFF', fontFamily: 'Montserrat, sans-serif', fontSize: 16, fontWeight: 300, }}>
                                            {translate('common.481')}<br/>{getDate(Number(formik.values.created) + 900000)}
                                        </span>
                                    )
                                }
                                {
                                    file && !isLoading && (
                                        <span style={{ color: '#FFF', fontFamily: 'Montserrat, sans-serif', fontSize: 16, fontWeight: 300, }}>
                                            {translate('common.482')}
                                        </span>
                                    )
                                }
                                {
                                    file && !isLoading && (
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <div style={{ padding: '5px 10px', background: 'linear-gradient(90deg, #6F59A3 0%, #9280BA 50%, #9280BA 99.99%)', borderRadius: 3, cursor: 'pointer' }} onClick={onSubmit}>
                                                <span style={{ color: '#FFF', fontFamily: 'Montserrat, sans-serif', fontSize: 14, fontWeight: 300 }}>{translate('common.483')}</span>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    file && isLoading && (
                                        <div style={{display: 'flex', justifyContent: 'center'}}>
                                            <CircularProgress />
                                        </div>
                                        
                                    )
                                }
                            </div>

                        </div>
                    </div>

                )
            }

        </div >

    )
}

export default Card;
import React from 'react';
import GamerItem from './gamerItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useRouter } from '../../hook/useRouter';
import {ReactComponent as Timer} from '../../img/timer.svg';
import Peace from '../../img/peace-tournament.jpeg';
import { translate } from '../../../dictionaries';
import Banner1 from '../../../@casino/img/tournament_banner/banner1.jpg';
import Banner2 from '../../../@casino/img/tournament_banner/banner2.jpg';
import Banner3 from '../../../@casino/img/tournament_banner/banner3.jpg';
import Banner4 from '../../../@casino/img/tournament_banner/banner4.jpg';
import Banner5 from '../../../@casino/img/tournament_banner/banner5.jpg';
import Banner6 from '../../../@casino/img/middleBanner.jpg';
import Banner7 from '../../../@casino/img/banner7.png';
import Banner8 from '../../../@casino/img/banner8.png';
import Banner9 from '../../../@casino/img/banner9.jpg';
import Banner10 from '../../../@casino/img/banner10.png';
import Banner11 from '../../../@casino/img/Banner11.png';

const useStyles = makeStyles((theme) => ({
    root: {
        background: '#0034A5', 
        width: 560, 
        display: 'flex',
        flexDirection: 'column', 
        boxShadow: '0px 10px 20px rgba(0, 11, 36, 0.5)', 
        borderRadius: 3,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            maxWidth: 560,
            margin: '0 auto',
        }
    },
    wrapTitle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 51,
        marginBottom: 35,
    },
    titleTournamentWeek: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 16,
        lineHeight: '20px',
        color: '#fff', 
        textAlign: 'center',
        marginBottom: 15
    },
    labelTournament: {
        margin: 0,
        padding: 0,
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '30px',
        lineHeight: '33px',
        color: '#7B61FF',
        textAlign: 'center',
        textTransform: 'uppercase',
        fontWeight: 700,
        textShadow: '1px 1px 2px black, 0 0 1em blue, 0 0 0.2em blue',
    },
    wrapFund: {
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center'
    },
    labelFund: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '29px',
        color: '#FF005C',
        textShadow: '1px 1px 2px red, 0 0 1em blue, 0 0 0.2em blue',
    },
    fund: {
        margin: 0,
        padding: 0,
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '80px',
        textTransform: 'uppercase',
        color: '#fff',
        textShadow: '1px 1px 2px red, 0 0 1em blue, 0 0 0.2em blue',
        [theme.breakpoints.down('sm')]: {
            fontSize: 50
        }
    },
    wrapBtn: {
        display: 'flex', 
        justifyContent: 'center', 
        marginTop: 30, 
        marginBottom: 57
    },
    btn: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '22px',
        padding: '20px 80px',
        textAlign: 'center',
        background: '#FF005C',
        borderRadius: '50px',
        color: '#fff',
        boxShadow: '0px 4px 10px #D3193A',
        cursor: 'pointer',
        transition: 'box-shadow .3s ease',
        '&:hover': {
            boxShadow: 'none',
            transition: 'box-shadow .3s ease',
        }
    },
    line: {
        border: '2px solid #001137'
    },
    wrapLeader: {
        padding: '33px 31px 32px 30px', 
        minHeight: 100,
        background: 'rgba(0, 17, 55, .5)'
    },
    wrapTitleLeader: {
        marginBottom: 23,
        display: 'flex',
        justifyContent: 'center'
    },
    titleLeader: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        color: '#7B61FF',
        textAlign: 'center'
    },
    listLeaders: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    wrapUsers: {
        display: 'grid', 
        gridTemplateColumns: '170px 170px 170px',
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '170px 170px',
            rowGap: 10
        },

    }
}))

const Tournament = ({name, fund, id, users, date_to, date_from, home}) => {
    const classes = useStyles();
    const router = useRouter();
    const now = new Date().getTime();
    
    const getDate = (val) => {
        const now = new Date().getTime();
        const diff = +val - now;
    
        let hours = (diff / 1000) / 60 / 60;
        let minutes = (diff - Math.floor(hours) * 1000 * 60 * 60) / 1000 / 60  ;
        let seconds = (diff - (Math.floor(hours) * 1000 * 60 * 60 + Math.floor(minutes) * 1000 * 60)) / 1000;
        
        return `${Math.floor(hours) < 10 ? '0' + Math.floor(hours) : Math.floor(hours)} : ${Math.floor(minutes) < 10 ? '0' +Math.floor(minutes) : Math.floor(minutes)} : ${Math.round(seconds) < 10 ? '0' + Math.round(seconds) : Math.round(seconds)}`;

    }
    
    const [left, setLeft] = React.useState(1);

    let timer = null;
        
    React.useEffect(() => {
        timer = setInterval(() => {
            if (left < 10) {
                setLeft(left + 1);
            } else {
                setLeft(1)
            }
        }, 1000);

        return () => {
            clearInterval(timer);
        }
    }, [left])

    const background = [
        {},
        {bg: Banner5, pos: -350},
        {bg: Banner4, pos: -350},
        {bg: Banner1},
        {bg: Banner2},
        {bg: Banner3},
        {bg: Banner6},
        {bg: Banner7},
        {bg: Banner8},
        {bg: Banner9},
        {bg: Banner10},
        {bg: Banner10},
        {bg: Banner10},
        {bg: Banner11},
        {bg: Peace},
        {bg: Peace},
        {bg: Peace},
        {bg: Peace},
        {bg: Peace},
        {bg: Peace},
        {bg: Peace},
        {bg: Peace},
        {bg: Peace},
        {bg: Peace},
        {bg: Peace}
    ]

    return (
        <div className={classes.root} style={{position: 'relative', marginBottom: 20, background: id && `url(${background[id].bg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
            <span style={{
                position: 'absolute', 
                top: 0, 
                left: 30, 
                fontFamily: 'Montserrat, sans-serif',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '17px',
                display: 'flex',
                alignItems: 'center',
                textAlign: 'center',
                color: '#FFFFFF',
                padding: '10px 25px', 
                background: +date_to > now  ? '#7B61FF' : '#001137',
                borderRadius: '0px 0px 20px 20px'
            }}>{+date_to > now ? translate('common.active') : +date_from > now ? translate('common.upcoming') : translate('common.finish')}</span>
            {
                +date_to > now && now > +date_from &&
                <div style={{
                    position: 'absolute', 
                    top: 0, 
                    right: 30, 
                    fontFamily: 'Montserrat, sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '14px',
                    display: 'flex',
                    alignItems: 'center',
                    textAlign: 'center',
                    color: '#FFFFFF',
                    padding: '10px 25px', 
                    background: '#001137',
                    borderRadius: '0px 0px 20px 20px',
                }}>
                    <Timer style={{width: 16, height: 16, marginRight: 8}}/>
                    <span>
                        {getDate(date_to)}
                    </span>
                </div>    
            }
            {
                now < +date_from &&
                <div style={{
                    position: 'absolute', 
                    top: 0, 
                    right: 30, 
                    fontFamily: 'Montserrat, sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '14px',
                    display: 'flex',
                    alignItems: 'center',
                    textAlign: 'center',
                    color: '#FFFFFF',
                    padding: '10px 25px', 
                    background: '#001137',
                    borderRadius: '0px 0px 20px 20px',
                }}>
                    <span>{translate('common.start')} &nbsp;</span>
                    <Timer style={{width: 16, height: 16, marginRight: 8}}/>
                    <span>
                        {getDate(date_from)}
                    </span>
                </div>    
            }
            <div style={{minHeight: home ? 593 : 500}}>
                <div className={classes.wrapTitle} style={{minHeight: 50}}>
                
                    {
                        name && 
                        <>
                            <span className={classes.titleTournamentWeek}>{translate('tournament.title')}</span>
                            <h3 className={classes.labelTournament}>{name}</h3>
                        </>
                    } 
                </div>
                <div className={classes.wrapFund} style={{paddingTop: !name && 200}}>
                    <span className={classes.labelFund}>{translate('tournament.prizefond')}</span>
                    <h3 className={classes.fund}>{new Intl.NumberFormat('ru-RU').format(fund)}</h3>
                </div>
                
                <div className={classes.wrapBtn}>
                    <span className={classes.btn} onClick={() => router.history.push(`/tournament/view/${id}`)}>{+date_from > now ? translate('tournament.startgame') : translate('lottery.checkResult')}</span>
                </div>
            </div>
            <div className={classes.line}></div>
            {
                name && !home &&
                <div className={classes.wrapLeader} >
                    <div className={classes.wrapTitleLeader}>
                        <span className={classes.titleLeader}>
                            {translate('tournament.liders')}
                        </span>
                    </div>
                    
                    <div className={classes.listLeaders}>
                        {
                            users.length !== 0 &&
                            <div className={classes.wrapUsers}>
                                {
                                    users.map((item, key) => key < 3 && <GamerItem key={key} label_name={item.id} amount={item.points} currency=""/>)
                                }  
                            </div>
                        }
                        {
                            users.length === 0 &&
                            <div style={{minHeight: 57, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <span style={{
                                        color: '#eee',
                                        fontSize: 14,
                                        fontStyle: 'normal',
                                        textAlign: 'center',
                                        fontFamily: 'Montserrat, sans-serif',
                                        fontWeight: 600,
                                }}>{translate('tournament.notFoundLiders')}</span>
                            </div>
                        }
                    </div>
                </div>  
            }
        </div>
    )
}

export default Tournament;